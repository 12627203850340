import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  License,
  NodeLicenseChangeableData,
  NoLicenseId,
} from "src/features/licenses/licenses.defenitions";
import { PlethoraApp } from "src/general.defenitions";
import { setPendingChanges } from "../../node-tree-records.slice";
import LicenseItem from "./LicenseItem";
import NoAssumedLicenseItem from "./NoAssumedLicenseItem";

interface NodeLicenseAppGroupProps {
  product: PlethoraApp;
  mergedLicenses: NodeLicenseChangeableData | undefined;
  licenses: License[] | undefined;
  selectedLicenseId: number | null | undefined;
  assumedLicense: License | null | undefined;
  viewOnly?: boolean;
}

const NodeLicenseAppGroup: React.FC<NodeLicenseAppGroupProps> = ({
  product,
  mergedLicenses,
  licenses,
  selectedLicenseId,
  assumedLicense,
}) => {
  const dispatch = useDispatch();

  const handleLicenseSelect = (licenseId: number, selected?: boolean) => {
    const licensesActiveKey =
      product === PlethoraApp.CT
        ? "ctActiveLicenseId"
        : "scienceActiveLicenseId";
    const selectedAssumedLicense =
      (assumedLicense && assumedLicense.id === licenseId) || licenseId === 0;
    const selectedNoLicense = licenseId === -1;
    if (selected && !selectedAssumedLicense && !selectedNoLicense) {
      dispatch({
        type: setPendingChanges.type,
        payload: {
          licenses: {
            [licensesActiveKey]: licenseId,
          },
        },
      });
    }

    if (selectedNoLicense) {
      const noLicenseId =
        product === PlethoraApp.CT ? NoLicenseId.CT : NoLicenseId.COSMOS;
      dispatch({
        type: setPendingChanges.type,
        payload: {
          licenses: {
            ...mergedLicenses,
            [licensesActiveKey]: selected ? noLicenseId : null,
          },
        },
      });
    }
    if (selectedAssumedLicense) {
      dispatch({
        type: setPendingChanges.type,
        payload: {
          licenses: {
            [licensesActiveKey]: null,
          },
        },
      });
    }
  };

  return (
    <div>
      <LicenseItem
        license={
          {
            id: -1,
            name: "No License",
            maxUsers: 0,
            currentUsers: 0,
            expirationDate: "",
          } as License
        }
        allocateMode={false}
        selected={
          selectedLicenseId === -1 ||
          selectedLicenseId === NoLicenseId.CT ||
          selectedLicenseId === NoLicenseId.COSMOS
        }
        onSelect={handleLicenseSelect}
      />

      {assumedLicense ? (
        <LicenseItem
          license={assumedLicense}
          assumedLicense={true}
          allocateMode={false}
          selected={selectedLicenseId === null}
          onSelect={handleLicenseSelect}
        />
      ) : (
        <NoAssumedLicenseItem
          selected={selectedLicenseId === null}
          product={product}
        />
      )}

      {licenses &&
        // do not include "NO LICENSE" in the list
        licenses
          .filter((license) => license.id !== 0)
          .map((license) => (
            <LicenseItem
              key={license.id}
              license={license}
              allocateMode={false}
              selected={selectedLicenseId === license.id}
              onSelect={handleLicenseSelect}
            />
          ))}
    </div>
  );
};

export default NodeLicenseAppGroup;
