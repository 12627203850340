import {
  BankOutlined,
  FolderOutlined,
  LineOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import TreeItem from "@mui/lab/TreeItem";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import {
  nodeRecordsSelectors,
  selectChildrenByParentId,
} from "../node-tree-records.slice";
import { NodeRecord, NodeType } from "../node-tree.defenitions";
import TreeItemActions from "./TreeItemActions";
import TreeItemLabel from "./TreeItemLabel";
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect, useMemo, useState } from "react";

const WrappedTreeNode = ({ nodeId }: WrappedTreeNodeProps) => {
  const { formatMessage } = useIntl();
  const childrenNodes = useSelector((state: RootState) =>
    selectChildrenByParentId(state, nodeId)
  );

  const selectedNodeId = useSelector(
    (state: RootState) => state.nodeRecords.selectedNodeId
  );

  const node = useSelector(
    (state: RootState) =>
      nodeRecordsSelectors.selectById(state.nodeRecords, nodeId) as NodeRecord
  );

  const sortedChildrenNodes = useMemo(() => {
    return childrenNodes.sort((a, b) => a.name.localeCompare(b.name));
  }, [childrenNodes]);

  return (
    <TreeItem
      nodeId={`${node.id}`}
      collapseIcon={nodeIcon(node.type)}
      expandIcon={nodeIcon(node.type)}
      label={
        <TreeItemLabel
          name={
            node.type === NodeType.GRADE
              ? formatMessage({
                  id: node.name
                    ? `grades.${node.name}`
                    : "nodes.newNames.grade",
                })
              : node.name
          }
          actions={
            selectedNodeId === node.id && (
              <TreeItemActions
                nodeId={nodeId}
                nodeType={node.type}
                nodeKey={node.key}
              />
            )
          }
        />
      }
    >
      {sortedChildrenNodes.map((child) => (
        <WrappedTreeNode key={child.id} nodeId={child.id} />
      ))}
      {childrenNodes.length === 0 && node.type !== NodeType.CLASS && (
        <TreeItem
          icon={<LineOutlined />}
          nodeId="empty"
          disabled
          label={<FormattedMessage id="nodes.node.empty" />}
        />
      )}
    </TreeItem>
  );
};

export default WrappedTreeNode;

interface WrappedTreeNodeProps {
  nodeId: number;
}

export const nodeIcon = (nodeType: NodeType) => {
  switch (nodeType) {
    case NodeType.GROUP:
      return <FolderOutlined />;
    case NodeType.CLASS:
      return <TeamOutlined />;
    case NodeType.SCHOOL:
      return <BankOutlined />;
    default:
      return undefined;
  }
};
