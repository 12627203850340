import { Form, Input, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store";
import {
  NodeChangeableData,
  selectCurrentNode,
  setPendingChanges,
} from "../../node-tree-records.slice";
import "./NodeEditor.scss";
import { NodeType } from "../../node-tree.defenitions";

const NodeEditor = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const editCurrentNode = useSelector(
    (state: RootState) => state.nodeRecords.editCurrentNode
  );

  const selectedNode = useSelector((state: RootState) =>
    selectCurrentNode(state)
  );

  const [form] = useForm();

  useEffect(() => {
    form.setFieldsValue({
      name:
        selectedNode?.type === NodeType.GRADE
          ? formatMessage({
              id: selectedNode?.name
                ? `grades.${selectedNode.name}`
                : "nodes.newNames.grade",
            })
          : selectedNode?.name,
      externalId: selectedNode?.externalId,
    });
  }, [selectedNode]);

  const onNodeUpdate = (
    changed: Partial<NodeChangeableData>,
    all: Partial<NodeChangeableData>
  ) => {
    dispatch({
      type: setPendingChanges.type,
      payload: all,
    });
  };

  return (
    <div className="node-editor">
      <Form form={form} onValuesChange={onNodeUpdate}>
        <Form.Item name="name" rules={[{ required: true }]}>
          {selectedNode?.type !== NodeType.GRADE ? (
            <Input
              className="node-editor__input"
              type="text"
              disabled={!editCurrentNode}
              placeholder={formatMessage({ id: "nodes.name" })}
            />
          ) : (
            <Select
              className="node-editor__input"
              options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((grade) => {
                return {
                  label: <FormattedMessage id={`grades.${grade}`} />,
                  value: grade,
                };
              })}
            />
          )}
        </Form.Item>
        <Form.Item name="externalId">
          <Input
            type="text"
            className="node-editor__input"
            disabled={!editCurrentNode}
            placeholder={formatMessage({ id: "nodes.externalId" })}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default NodeEditor;
