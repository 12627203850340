import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { selectCurrentNode } from "../../node-tree-records.slice";
import { NodeRecord } from "../../node-tree.defenitions";
import DisplayCode from "./DisplayCode";
import "./NodeUnchangableData.scss";

const NodeUnchangableData = () => {
  const selectedNode = useSelector((state: RootState) =>
    selectCurrentNode(state)
  ) as NodeRecord;

  return (
    <div className="node-unchangable-data">
      <div className="node-unchangable-data__id">{selectedNode.id}</div>
      {selectedNode.codes?.map((code) => (
        <DisplayCode key={code} code={code} />
      ))}
    </div>
  );
};

export default NodeUnchangableData;
