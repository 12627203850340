import {
  createEntityAdapter,
  createSelector,
  createSlice,
  current,
  EntityState,
  PayloadAction,
  Update,
} from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { NodeLicenseChangeableData } from "../licenses/licenses.defenitions";
import { getNodeInfo, getNodeTree } from "./node-tree.api";
import {
  BackendNodeRecord,
  NodeRecord,
  NodeRecordReplaceRequest,
  PatNodeRecordInfo,
} from "./node-tree.defenitions";

const nodeRecordsAdapter = createEntityAdapter<NodeRecord>();

export const nodeRecordsSelectors = nodeRecordsAdapter.getSelectors();

const nodeRecordsSlice = createSlice({
  name: "nodeRecords",
  initialState: nodeRecordsAdapter.getInitialState<NodeTreeInitialState>({
    selectedNodeId: null,
    selectedNodeInfo: null,
    editCurrentNode: false,
    expandedNodes: [],
    pendingChanges: null,
    nodeLoading: false,
  }),
  reducers: {
    setSelectedNodeId(
      slice: NodeTreeSlice,
      action: PayloadAction<number | null>
    ) {
      slice.selectedNodeId = action.payload;
      slice.editCurrentNode = false;

      slice.pendingChanges = null;
    },
    setPendingChanges(
      slice: NodeTreeSlice,
      action: PayloadAction<Partial<NodeChangeableData | null>>
    ) {
      if (action.payload !== undefined && slice.selectedNodeInfo) {
        const currentLicenses: NodeLicenseChangeableData = {
          nodeLicensesIds: slice.selectedNodeInfo.licenses.nodeLicensesIds,
          scienceActiveLicenseId:
            slice.selectedNodeInfo.licenses.scienceActiveLicenseId,
          ctActiveLicenseId: slice.selectedNodeInfo.licenses.ctActiveLicenseId,
        };
        const licenses = {
          ...currentLicenses,
          ...slice.pendingChanges?.licenses,
          ...action.payload?.licenses,
        };

        slice.pendingChanges = {
          ...slice.pendingChanges,
          ...action.payload,
          licenses,
        };
        console.log("slice.pendingChanges: ", slice.pendingChanges);
      } else {
        slice.pendingChanges = null;
      }
    },
    setExpandedNodes(slice: NodeTreeSlice, action: PayloadAction<string[]>) {
      slice.expandedNodes = action.payload;
    },
    setEditCurrentNode(slice: NodeTreeSlice, action: PayloadAction<boolean>) {
      slice.editCurrentNode = action.payload;
    },
    addNode(slice: NodeTreeSlice, action: PayloadAction<NodeRecord>) {
      nodeRecordsAdapter.addOne(slice, action.payload);
      const parentNodeId = action.payload.parentNodeId;
      if (parentNodeId) {
        // if parent node was a leaf, we'll update it to be a non-leaf
        const update: Update<NodeRecord> = {
          id: parentNodeId,
          changes: { isLeaf: false },
        };
        nodeRecordsAdapter.updateOne(slice, update);
      }
      // next we need to set the selected node to the newly added node and set it to edit
      slice.selectedNodeId = action.payload.id;
      slice.editCurrentNode = true;

      // and we need to expand the parent node
      if (parentNodeId) {
        slice.expandedNodes = [...slice.expandedNodes, `${parentNodeId}`];
      }
    },
    replaceUnsavedNode(
      slice: NodeTreeSlice,
      action: PayloadAction<NodeRecordReplaceRequest>
    ) {
      // this action is intended to replace the unsaved node with the newly added node
      if (!slice.selectedNodeId) return;

      nodeRecordsAdapter.updateOne(slice, {
        id: slice.selectedNodeId,
        changes: {
          id: action.payload.insertId,
          codes: action.payload.codes,
          newNode: false,
          title: action.payload.name,
          name: action.payload.name,
          externalId: action.payload.externalId,
        },
      });

      slice.selectedNodeId = action.payload.insertId;
      slice.editCurrentNode = false;
      slice.pendingChanges = null;
    },
    savePendingChanges(slice: NodeTreeSlice) {
      console.log("savePendingChanges: ", current(slice.pendingChanges));
      if (!slice.selectedNodeId || !slice.pendingChanges) return;

      // name update
      if (slice.pendingChanges.name) {
        nodeRecordsAdapter.updateOne(slice, {
          id: slice.selectedNodeId,
          changes: { ...slice.pendingChanges },
        });
      }

      // licenses update
      if (slice.selectedNodeInfo && slice.pendingChanges.licenses) {
        slice.selectedNodeInfo.licenses.nodeLicensesIds =
          slice.pendingChanges.licenses.nodeLicensesIds;
        slice.selectedNodeInfo.licenses.scienceActiveLicenseId =
          slice.pendingChanges.licenses.scienceActiveLicenseId;
        slice.selectedNodeInfo.licenses.ctActiveLicenseId =
          slice.pendingChanges.licenses.ctActiveLicenseId;
      }
      console.log(
        "savePendingChanges slice.selectedNodeInfo.licenses: ",
        current(slice.selectedNodeInfo?.licenses)
      );
      slice.editCurrentNode = false;
      slice.pendingChanges = null;
    },
    removeNode(slice: NodeTreeSlice, action: PayloadAction<number>) {
      nodeRecordsAdapter.removeOne(slice, action.payload);
      slice.editCurrentNode = false;
      slice.pendingChanges = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getNodeTree.fulfilled,
      (slice: NodeTreeSlice, action: PayloadAction<BackendNodeRecord[]>) => {
        const processedNodeRecords = processBackendNodeRecords(action.payload);
        nodeRecordsAdapter.setAll(slice, processedNodeRecords);
      }
    );
    builder.addCase(getNodeTree.rejected, (slice: NodeTreeSlice) => {
      nodeRecordsAdapter.setAll(slice, []);
    });

    builder.addCase(
      getNodeInfo.fulfilled,
      (slice: NodeTreeSlice, action: PayloadAction<PatNodeRecordInfo>) => {
        slice.selectedNodeInfo = action.payload;
        slice.nodeLoading = false;
      }
    );

    builder.addCase(getNodeInfo.pending, (slice: NodeTreeSlice) => {
      slice.nodeLoading = true;
      slice.selectedNodeInfo = null;
    });

    builder.addCase(getNodeInfo.rejected, (slice: NodeTreeSlice) => {
      slice.nodeLoading = false;
      slice.selectedNodeInfo = null;
    });
  },
});

export default nodeRecordsSlice.reducer;

export const {
  setSelectedNodeId,
  addNode,
  setExpandedNodes,
  setEditCurrentNode,
  removeNode,
  replaceUnsavedNode,
  setPendingChanges,
  savePendingChanges,
} = nodeRecordsSlice.actions;

export const selectRootNodesIds = createSelector(
  [nodeRecordsSelectors.selectAll],
  (nodes): number[] =>
    nodes.reduce((rootNodes: number[], node) => {
      if (
        node.parentNodeId === null ||
        !nodes.find((el) => el.id === node.parentNodeId)
      ) {
        rootNodes.push(node.id);
      }
      return rootNodes;
    }, [])
);

export const selectCurrentNode = createSelector(
  [
    (state: RootState) => state.nodeRecords,
    (state: RootState) => state.nodeRecords.selectedNodeId,
  ],
  (nodeRecords, selectedNodeId) => {
    if (!selectedNodeId) return null;
    return nodeRecordsSelectors.selectById(nodeRecords, selectedNodeId);
  }
);

export const selectChildrenByParentId = createSelector(
  [
    (state: RootState) => nodeRecordsSelectors.selectAll(state.nodeRecords),
    (_: RootState, parentId) => parentId,
  ],
  (nodes, parentId): NodeRecord[] => {
    const children = nodes.filter((node) => node.parentNodeId === parentId);
    return children;
  }
);

const processBackendNodeRecords = (
  nodeRecords: BackendNodeRecord[]
): NodeRecord[] => {
  // we'll start with finding the root nodes
  const processedRootNodes: NodeRecord[] = nodeRecords
    .filter(
      (node) =>
        node.parentNodeId === null ||
        !nodeRecords.find((el) => el.id === node.parentNodeId)
    )
    .map((rootNode) => {
      return {
        ...rootNode,
        key: `${rootNode.id}`,
        isLeaf: !nodeRecords.find((el) => el.parentNodeId === rootNode.id),
        title: rootNode.name,
      };
    });

  //const processedRootLeafs = processedRootNodes.filter((node) => node.isLeaf);

  // now we'll remove the root nodes from the array
  const restOfNodes = nodeRecords.filter(
    (node) => !processedRootNodes.find((el) => el.id === node.id)
  );

  // now, we'll run through the nodes and find their children
  const processedNodes = iterateThroughNodes(processedRootNodes, restOfNodes);

  return processedNodes;
};

const iterateThroughNodes = (
  processedNodes: NodeRecord[],
  nodeRecords: BackendNodeRecord[]
): NodeRecord[] => {
  // we'll filter the processed nodes to contain only the nodes with children
  const processedNodesWithChildren = processedNodes.filter(
    (node) => !node.isLeaf
  );

  const newProcessedNodes: NodeRecord[] = nodeRecords
    .filter((node) =>
      processedNodesWithChildren.find(
        (processed) => processed.id === node.parentNodeId
      )
    )
    .map((node) => {
      const parentNode = processedNodesWithChildren.find(
        (processed) => processed.id === node.parentNodeId
      ) as NodeRecord;

      return {
        ...node,
        key: `${parentNode.key}-${node.id}`,
        isLeaf: !nodeRecords.find((el) => el.parentNodeId === node.id),
        title: node.name,
      };
    });

  // now we'll update the list of processed nodes
  processedNodes = [...processedNodes, ...newProcessedNodes];

  // and check if we still have unprocessed records left
  const restOfNodes = nodeRecords.filter(
    (node) => !processedNodes.find((el) => el.id === node.id)
  );

  if (restOfNodes.length > 0) {
    return iterateThroughNodes(processedNodes, restOfNodes);
  } else {
    return processedNodes;
  }
};

interface NodeTreeInitialState {
  selectedNodeId: number | null;
  selectedNodeInfo: PatNodeRecordInfo | null;
  editCurrentNode: boolean;
  expandedNodes: string[];
  pendingChanges: Partial<NodeChangeableData> | null;
  nodeLoading: boolean;
}

export interface NodeChangeableData {
  name: string;
  externalId?: string | number | null;
  licenses: NodeLicenseChangeableData;
}

type NodeTreeSlice = EntityState<NodeRecord> & NodeTreeInitialState;
