import { PlethoraApp } from "src/general.defenitions";
import { NodeType } from "../node-tree/node-tree.defenitions";

export interface NodeLicenseData extends NodeLicenseChangeableData {
  /** admin licenses are licenses which admin can
   * assign to nodes under his admin root node
   */
  adminLicenses: License[];
  /** node licenses are the licenses which are set directly on the node */
  scienceAssumedLicense: License | null;
  ctAssumedLicense: License | null;
}

export interface NodeLicenseChangeableData {
  nodeLicensesIds: number[];
  scienceActiveLicenseId: number | null;
  ctActiveLicenseId: number | null;
}

export interface LicenseWithNodeId extends License {
  nodeId: number;
  active: boolean;
}

export interface LicenseFormItem {
  key: number;
  id: number;
  name: string;
  product: PlethoraApp | null;
  expirationDate: string;
  period: number | null;
  maxUsers: number;
  currentUsers: number;
  contentPackageId: number | null;
  contentPackageLessons: string;
  activeNodes?: ActiveNodeData[];
  delete?: boolean;
}

export enum NoLicenseId {
  CT = 0,
  COSMOS = 14,
}

export interface License extends LicenseStub {
  id: number;
  name: string;
  product: PlethoraApp | null;
  expirationDate: string;
  period: number | null;
  maxUsers: number;
  currentUsers: number;
  data: LicenseData;
}

export interface LicenseWithNodeInfo extends License {
  activeNodes?: ActiveNodeData[];
}

export interface ActiveNodeData {
  nodeId: number;
  nodeName: string;
  nodeType: NodeType;
  isActive: boolean;
}

export interface LicenseStub {
  id: number;
  name: string;
  product: PlethoraApp | null;
}

export interface LicenseData {
  contentPackage: ContentPackage | null;
}

export interface ContentPackage {
  id: number;
  name: string;
  description: string;
  lessons?: string[];
}

export interface AllLicensesResponse {
  licenses: License[];
  scienceContentPackages: ContentPackage[];
  ctContentPackages: ContentPackage[];
}

export interface LicenseUpsertResponse {
  addedIds: { [key: number]: number };
}

export interface SaveLisensesResponse {
  pendingLicenses: License[];
  addedIds: { [key: number]: number };
}
