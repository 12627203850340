import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { NodeLicenseChangeableData } from "../licenses/licenses.defenitions";
import {
  NodeChangeableData,
  nodeRecordsSelectors,
  removeNode,
  replaceUnsavedNode,
  savePendingChanges,
  setSelectedNodeId,
} from "./node-tree-records.slice";
import {
  addNodeBackend,
  deleteNodeBackend,
  editNodeBackend,
  getNodeInfo,
} from "./node-tree.api";
import { NodeRecord } from "./node-tree.defenitions";

export const postNewNodeAction = createAsyncThunk<
  any,
  undefined,
  { state: RootState }
>("postNewNodeAction", async (param, { getState, dispatch }) => {
  const state = getState();
  const currentNodeId = state.nodeRecords.selectedNodeId;
  // if we don't have a current node id it's a sort of the bug and there's nothing to do
  if (!currentNodeId) return;
  const nodesState = state.nodeRecords;
  const selectedNode = nodesState.entities[currentNodeId];
  // same if we don't have a selected node or name in pending changes
  if (!selectedNode) return;
  // we'll update the node's name and will post it to the backend
  const newNode: NodeRecord = {
    ...selectedNode,
    ...nodesState.pendingChanges,
  };
  const response = await dispatch(addNodeBackend(newNode)).unwrap();
  // now we will need to update the slice with the db response
  dispatch({
    type: replaceUnsavedNode.type,
    payload: { ...response, ...nodesState.pendingChanges },
  });
});

export interface AddNodeRequest {
  name: string;
}

export const deleteNodeAction = createAsyncThunk<
  any,
  undefined,
  { state: RootState }
>("deleteNodeAction", async (param, { getState, dispatch }) => {
  const state = getState();
  const nodesState = state.nodeRecords;
  const currentNodeId = nodesState.selectedNodeId;
  // if we don't have a current node id it's a sort of the bug and there's nothing to do
  if (!currentNodeId) return;
  const selectedNode = nodesState.entities[currentNodeId];
  // same if we don't have a selected node or name in pending changes
  if (!selectedNode) return;

  // now we need to check if this node has children
  const hasChildren = nodeRecordsSelectors
    .selectAll(nodesState)
    .some((node) => node.parentNodeId === currentNodeId);
  // and if it does, we should not proceed with the deletion
  if (hasChildren) return;

  // now we can delete the node
  await dispatch(deleteNodeBackend(currentNodeId));

  // now we should activate another node: either previous child
  // or parent if there's no previous child
  // we need to find the node's parent first
  const parentNode = nodeRecordsSelectors.selectById(
    nodesState,
    selectedNode.parentNodeId || 0
  );
  // and then find the previous child
  const children = nodeRecordsSelectors
    .selectAll(nodesState)
    .filter((node) => node.parentNodeId === selectedNode.parentNodeId);
  const currentNodeIndex = children.findIndex(
    (node) => node.id === currentNodeId
  );
  if (currentNodeIndex < 1 && parentNode) {
    // if there's no previous child, we'll activate the parent
    dispatch({
      type: setSelectedNodeId.type,
      payload: parentNode.id,
    });
    dispatch({ type: removeNode.type, payload: currentNodeId });
    dispatch(getNodeInfo(parentNode.id));
  }

  // if there's a previous child, we'll activate it
  const previousChild = children[currentNodeIndex - 1];

  dispatch({
    type: setSelectedNodeId.type,
    payload: previousChild.id,
  });

  // and delete it from the slice as well
  dispatch({ type: removeNode.type, payload: currentNodeId });
  dispatch(getNodeInfo(previousChild.id));
});

export const editNodeAction = createAsyncThunk<
  any,
  undefined,
  { state: RootState }
>("editNodeAction", async (param, { getState, dispatch }) => {
  const state = getState();
  const nodesState = state.nodeRecords;
  const currentNodeId = nodesState.selectedNodeId;
  const currentNodeInfo = nodesState.selectedNodeInfo;
  // if we don't have a current node id it's a sort of the bug and there's nothing to do
  if (!currentNodeId) return;
  const selectedNode = nodesState.entities[currentNodeId];
  // same if we don't have a selected node
  //or nothing in pending changes
  if (
    !selectedNode ||
    Object.keys(nodesState.pendingChanges || {}).length === 0
  )
    return;
  // we'll update the node's name and will post it to the backend
  // we need to make sure we're not losing the license information
  const nodeLicenses: NodeLicenseChangeableData = {
    scienceActiveLicenseId: currentNodeInfo!.licenses.scienceActiveLicenseId,
    ctActiveLicenseId: currentNodeInfo!.licenses.ctActiveLicenseId,
    nodeLicensesIds: currentNodeInfo!.licenses.nodeLicensesIds,
    ...nodesState.pendingChanges?.licenses,
  };
  const nodeChanges: NodeRecord & NodeChangeableData = {
    ...selectedNode,
    ...nodesState.pendingChanges,
    licenses: nodeLicenses,
  };
  console.log("editNodeAction, nodeChanges", nodeChanges);
  await dispatch(editNodeBackend(nodeChanges));
  // and now we can update the slice
  dispatch({ type: savePendingChanges.type });
});
