import { Checkbox, Radio } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { License } from "src/features/licenses/licenses.defenitions";
import { RootState } from "src/store";
import "./LicenseItem.scss";

const LicenseItem = ({
  license,
  allocateMode,
  assumedLicense,
  selected,
  onSelect,
}: LicenseItemProps) => {
  const editCurrentNode = useSelector(
    (state: RootState) => state.nodeRecords.editCurrentNode
  );

  const onChange = (event: CheckboxChangeEvent) => {
    onSelect && onSelect(license.id, event.target.checked);
  };

  return (
    <div className="license-item">
      <div className="license-item__selector">
        {allocateMode ? (
          <Checkbox
            key={license.id}
            onChange={onChange}
            defaultChecked={selected}
            checked={selected}
            disabled={!editCurrentNode}
          />
        ) : (
          <Radio
            key={0}
            onChange={onChange}
            defaultChecked={selected}
            checked={selected}
            disabled={!editCurrentNode}
          />
        )}
      </div>
      <div>
        {license.id !== -1 && (
          <span style={{ color: "grey", marginRight: "8px" }}>
            id: {license.id}
          </span>
        )}
        {assumedLicense && (
          <span style={{ marginRight: "8px" }}>
            <FormattedMessage id="nodes.node.license.assumedLicense" />
          </span>
        )}
        {license.name}
        {license.id !== -1 && (
          <>
            | {license.maxUsers - license.currentUsers}
            <FormattedMessage id="nodes.node.licenseSeatsLeft" /> |{" "}
            <FormattedMessage id="nodes.node.licenseExpirationDate" />
            {new Date(license.expirationDate).toLocaleDateString()}{" "}
            {license.period ? (
              <>
                | <FormattedMessage id="nodes.node.licensePeriod" />{" "}
                {license.period}{" "}
                <FormattedMessage id="nodes.node.licensePeriodDays" />
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default LicenseItem;

interface LicenseItemProps {
  license: License;
  assumedLicense?: boolean;
  allocateMode: boolean;
  onSelect?: (licenseId: number, selected?: boolean) => void;
  selected?: boolean;
  viewOnly?: boolean;
}
