import { Radio } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store";
import { setPendingChanges } from "../../node-tree-records.slice";
import { PlethoraApp } from "src/general.defenitions";

const NoAssumedLicenseItem = ({
  product,
  selected,
}: NoAssumedLicenseItemProps) => {
  const dispatch = useDispatch();
  const editCurrentNode = useSelector(
    (state: RootState) => state.nodeRecords.editCurrentNode
  );

  const onChange = (event: CheckboxChangeEvent) => {
    const licensesActiveKey =
      product === PlethoraApp.CT
        ? "ctActiveLicenseId"
        : "scienceActiveLicenseId";

    dispatch({
      type: setPendingChanges.type,
      payload: {
        licenses: {
          [licensesActiveKey]: null,
        },
      },
    });
  };

  return (
    <div className="license-item">
      <div className="license-item__selector">
        <Radio
          key={0}
          onChange={onChange}
          defaultChecked={selected}
          checked={selected}
          disabled={!editCurrentNode}
        />
      </div>
      <div>
        <span style={{ marginRight: "8px" }}>
          <FormattedMessage id="nodes.node.license.assumedLicense" /> none
        </span>
      </div>
    </div>
  );
};

export default NoAssumedLicenseItem;

interface NoAssumedLicenseItemProps {
  product: PlethoraApp;
  selected: boolean;
}
